<template>
	<div>
		<div class="row mt-4 mb-4">
			<div class="col-sm-12 text-right">
				<h1>Servicio a clientes</h1>
			</div>
		</div>

		<div class="d-flex flex-wrap">
			<div v-if="$auth.can('servicio_a_clientes','solicitudes_listar')" class="col-md-4">
				<div class="bpb-card">
					<div class="card-body">
						<div class="text-center">
							<img src="https://static.bienparabien.com/constelacion/img/solicitudes.png" alt="Facturas" width="80px" />
						</div>
						<h4 class="card-title">Solicitudes</h4>
						<p class="card-subtitle text-muted">Administración de solicites pendientes por asignar</p>
						<router-link :to="{name: 'clientes_solicitudes'}" class="btn complementary-btn">Acceder</router-link>
					</div>
				</div>
			</div>
			<div v-if="$auth.can('servicio_a_clientes','usuarios_listar')" class="col-md-4">
				<div class="bpb-card">
					<div class="card-body">
						<div class="text-center">
							<img src="https://static.bienparabien.com/constelacion/img/account_usuarios.png" alt="Facturas" width="80px" />
						</div>
						<h4 class="card-title">Clientes</h4>
						<p class="card-subtitle text-muted">Administración de solicites pendientes por asignar</p>
						<router-link :to="{name: 'clientes_usuarios'}" class="btn complementary-btn">Acceder</router-link>
					</div>
				</div>
			</div>

			<div v-if="$auth.can('servicio_a_clientes','prospectos_listar')" class="col-md-4"> 
				<div class="bpb-card">
					<div class="card-body">
						<div class="text-center">
							<img src="https://static.bienparabien.com/constelacion/img/account_usuarios.png" alt="Prospectos" width="80px" />
						</div>
						<h4 class="card-title">Prospecto</h4>
						<p class="card-subtitle text-muted">Administración de prospectos</p>
						<router-link :to="{name: 'prospectos'}" class="btn complementary-btn">Acceder</router-link>
					</div>
				</div>
			</div>

			<div v-if="$auth.can('servicio_a_clientes','tickets_listar')" class="col-md-4">
				<div class="bpb-card">
					<div class="card-body">
						<div class="text-center">
							<img src="../assets/icons/buzon/buzon.png" alt="Buzón" width="80px" />
						</div>
						<h4 class="card-title">Buzón de ayuda</h4>
						<p class="card-subtitle text-muted">Buzón de ayuda</p>
						<router-link :to="{name: 'clientes_buzon'}" class="btn complementary-btn">Acceder</router-link>
					</div>
				</div>
			</div>
		</div>

		<div class="row mt-4 mb-4">
			<div class="col-sm-12 text-right">
				<h1>Administración</h1>
			</div>
		</div>

		<div class="d-flex flex-wrap">
			<div v-if="$auth.can('servicio_a_clientes','solicitudes_admin_catalogos')" class="col-md-4">
				<div class="bpb-card">
					<div class="card-body">
						<div class="text-center">
							<img src="https://static.bienparabien.com/constelacion/img/list_01_b.png" alt="Facturas" width="80px" />
						</div>
						<h4 class="card-title">Catálogos</h4>
						<p class="card-subtitle text-muted">Administración de los catálogos de la aplicación</p>
						<router-link :to="{name: 'clientes_admin_catalogos'}" class="btn complementary-btn">Acceder</router-link>
					</div>
				</div>
			</div>
			<div v-if="$auth.can('servicio_a_clientes','solicitudes_admin_accesos')" class="col-md-4">
				<div class="bpb-card">
					<div class="card-body">
						<div class="text-center">
							<img src="https://static.bienparabien.com/constelacion/img/access_01_b.png" alt="Facturas" width="80px" />
						</div>
						<h4 class="card-title">Accesos a sistema</h4>
						<p class="card-subtitle text-muted">Listado de accesos a los expedientes de los clientes</p>
						<router-link :to="{name: 'clientes_admin_accesos_expedientes'}" class="btn complementary-btn">Acceder</router-link>
					</div>
				</div>
			</div>
			<div v-if="$auth.can('servicio_a_clientes','configuracion_admin')" class="col-md-4">
				<div class="bpb-card">
					<div class="card-body">
						<div class="text-center">
							<img src="https://static.bienparabien.com/constelacion/img/gear.svg" alt="Configuración" width="80px" />
						</div>
						<h4 class="card-title">Configuración</h4>
						<p class="card-subtitle text-muted">Configuraciones del sistema</p>
						<router-link :to="{name: 'clientes_configuracion'}" class="btn complementary-btn">Acceder</router-link>
					</div>
				</div>
			</div>			
			<!-- <div v-if="$auth.can('servicio_a_clientes','solicitudes_admin_accesos')" class="col-md-4">
				<div class="card">
					<div class="card-body">
						<div class="text-center">
							<img src="https://static.bienparabien.com/constelacion/img/access_01_b.png" alt="Facturas" width="80px" />
						</div>
						<h4 class="card-title">Actividades</h4>
						<p class="card-subtitle text-muted">Listado de acciones realizadas dentro del sistema</p>
						<router-link :to="{name: 'clientes_admin_actividades_sistema'}" class="btn complementary-btn">Acceder</router-link>
					</div>
				</div>
			</div> -->
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		mounted: function() {
			let menu = {title: 'Servicio a Clientes'};

			if (this.$auth.can('servicio_a_clientes','solicitudes_listar'))
				menu.clientes_solicitudes = 'Solicitudes';

			if (this.$auth.can('servicio_a_clientes','usuarios_listar'))
				menu.clientes_usuarios = 'Clientes';

			if (this.$auth.can('servicio_a_clientes','prospectos_listar'))
				menu.prospectos = 'Prospectos';

			if (this.$auth.can('servicio_a_clientes','tickets_listar'))
				menu.clientes_buzon = 'Buzón de ayuda';

			if (this.$auth.can('servicio_a_clientes','*')) {
				let admin = {
					title: 'Administración'
					,clientes_admin_catalogos: 'Catálogos'
					,clientes_admin_accesos_expedientes: 'Accesos a expedientes'
					,clientes_configuracion: 'Configuración'
					// ,clientes_admin_actividades_sistema: 'Actividades'
				}

				this.$store.commit('setMenu',[menu,admin]);
			}else
				this.$store.commit('setMenu',[menu]);
		}
	}
</script>